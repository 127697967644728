/* Font */
@import url('https://fonts.googleapis.com/css2?family=Cardo&family=Crimson+Text&display=swap');

body {
  font-family: 'Crimson Text', serif, sans-serif;
}

h1, h2, h3, h4, h5, h6, legend, button, input, textarea {
  font-family: 'Cardo', serif, sans-serif;
}

/* Resets */
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
}

.app {
  background: black;
  background-image: url(./images/space1.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.component {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Navigation */
.nav {
  display: flex;
  border-bottom: 1px solid white;
  align-items: center;
  padding: 18px 0;
  margin: 0 20px;
}

.nav-links {
  display: flex;
  align-items: center;
  width: 100%;
}

.nav .hamburger {
  display: none;
}

.nav a {
  font-size: 1.5rem;
  text-decoration: none;
  color: white;
  transition: color 0.35s;
}

.nav a:hover {
  color: plum;
}

.nav .nav-links a:nth-child(2) {
  margin-left: auto;
}

.nav-links a:nth-child(3) {
  margin: auto 30px;
}

/* home page */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home .top {
  display: flex;
  margin: 25px 50px;
  height: 75vh;
  justify-content: center;
  align-items: center;
}

.home .top h1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 1;
}

.home .top h1 {
  text-shadow: 1px 1px 1px black;
  text-align: center;
}

.home .top h1 div:first-child {
  font-size: 3.5vw;
  position: relative;
  bottom: 50px;
  right: 35vw;
}

.home .top h1 div:nth-child(2) {
  font-size: 8vw;
}

.home .top h1 div:nth-child(3) {
  font-size: 3.5vw;
  position: relative;
  top: 50px;
  left: 35vw;
}

.home img {
  position: absolute;
  z-index: 0;
  height: 480px;
  width: 480px;
  border-radius: 50%;
  object-fit: cover;
  max-height: 70vh;
  max-width: 70vh;
}

.home .bottom {
  display: flex;
  justify-content: center;
}

.home .bottom a {
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  max-width: 90vw;
  color: black;
  /* margin-top: -25px; */
  border: 1px solid black;
  border-radius: 20px;
  /* background-color: paleturquoise; */
  background-color: white;
  padding: 7px 15px;
  transition: border 0.2s, color 0.4s, background-color 0.4s;
}

.home .bottom a:hover {
  color: plum;
  background-color: black;
  border: 1px solid plum;
}

.svg {
  position: fixed;
  bottom: 3%;
  left: 5%;
  width: 100px;
  height: 100px;
  background-image: url('./images/Rocket11.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* About Page */

.about {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  min-height: 90vh;
}

.about .top .text {
  display: flex;
  flex-direction: column;
}

.about .top .text h1 {
  color: white;
  text-align: center;
  margin: 0;
}

.about .top .text p {
  color: white;
  text-align: center;
}

.about .middle {
  margin-top: -2rem;
  /* max-width: 90vw; */
}

.about .middle p {
  color: white;
  text-align: center;
}

.about .middle .tools {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about .middle .tools p {
  font-size: 1.3rem;
}


.about .middle .logos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.about .logos img {
  margin: .5rem 1.5rem;
}

.about .bottom, .projects .bottom {
  display: flex;
  justify-content: center;
}

.about .bottom a, .projects .bottom a {
  text-align: center;
  text-decoration: none;
  font-size: 2rem;
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  padding: 5px 10px;
  transition: border 0.2s, color 0.4s, background-color 0.4s;
  transition-delay: 0;
  max-width: 90vw;
}

.about .bottom a:hover, .projects .bottom a:hover {
  color: plum;
  background-color: black;
  border: 1px solid plum;
}

.about .top p {
  color: white;
  text-align: center;
  font-size: 1.5rem;
}

/* Projects Page */

.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.projects .top, .projects .bottom {
  margin: 4vh auto;
}

.projects .top h1 {
  text-align: center;
  color: white;
  font-size: 1.8rem;
  margin-bottom: 0;
}

.projects .top .gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery .item h1 {
  color: white;
  text-align: center;
  font-size: 1.4rem;
}

.gallery figure {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 150px;
  border: 1px solid white;
}

.gallery img {
  height: 75%;
  width: 100%;
  object-fit: cover;
}

.gallery figcaption {
  display: flex;
  margin: auto 0;
  justify-content: space-around
}

.projects .top a {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  transition: color 0.35s;
}
.projects .top a:hover {
  color: plum;
}

.projects .bottom a {
  margin-top: -1rem;
  margin-bottom: 2rem;
}

/* Contact Page */
.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  min-height: 90vh;
}

.contact .top h1 {
  color: white;
  text-align: center;
  font-size: 1.5rem;
}

.contact .middle {
  display: flex;
  width: 500px;
  max-width: 90vw;
  justify-content: space-between;
}

.contact .middle a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.contact .middle a p {
  text-align: center;
  color: white;
  font-size: 1.5rem;
  transition: color 0.35s;
}

.contact .middle a p:hover {
  color: plum;
}


.contact .bottom form {
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 80vw;
}

.contact .bottom form legend {
  color: white;
  text-align: center;
  font-size: 1.5rem;
}

.contact .bottom form p {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact .bottom form label {
  color: white;
}

.contact .bottom form input {
  color: white;
  border: 2px solid white;
  border-radius: 10px;
  padding: 8px;
  font-size: 1.1rem;
  min-height: 2rem;
  background: none;
}

.contact .bottom form textarea {
  color: white;
  border: 2px solid white;
  border-radius: 10px;
  padding: 8px;
  font-size: 1.1rem;
  min-height: 5rem;
  background: none;
}

.contact .bottom form button {
  margin-top: 10px;
  font-size: 1.5rem;
  color: black;
  background-color: white;
  border: 1px solid white;
  border-radius: 10px;
  position: relative;
  transition: border 0.2s, color 0.4s, background-color 0.4s;
}

.contact .bottom form button:hover {
  cursor: pointer;
  color: plum;
  border: 1px solid plum;
  background-color: black;
}

.contact .bottom button:active {
  top: 10px;
}

.app .component .top {
  max-width: 90vw;
  margin: 10px auto;
}

.app .component .bottom a {
  max-width: 70vw;
}

.app .component .top h1,
.app .component .top p,
.app .component .middle h1,
.app .component .middle p {
  max-width: 90vw;
  margin: 10px auto;
}



/* Page Transitions */

/* Whole page */

/* first/initial appearance */
.page-appear {
  opacity: 0;
}
.page-appear-active {
  opacity: 1;
  transition: opacity 0.6s 0.6s;
}

.page-enter {
  opacity: 0;
  height: 0;
  width: 0;
}

.page-enter-active {
  transition: width 0s 0.6s, height 0s 0.6s, opacity 1s 0.6s;
  opacity: 1;
  height: 100%;
  width: 100%;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 0.6s;
}

/* Rocket */
/* First/initial appearrance */
.page-appear .svg {
  transform: translateY(200px) !important;
}
.page-appear-active .svg {
  transform: translateY(0) !important;
  transition: transform 0.6s 0.7s;
}

.page-enter .svg {
  transform: translateY(200px) !important;
}
.page-enter-active .svg {
  transform: translateY(0) !important;
  transition: transform 0.6s 0.6s;
}

.page-exit .svg {
  transform: translateY(0) !important;
}
.page-exit-active .svg {
  transform: translateY(-500px) !important;
  transition: transform 0.6s 0s;
}

/* Home page */

/* Upper Text (Hi there) */
/* appear */
.page-appear .home .top div:first-child {
  transform: translateX(200px)
}
.page-appear-active .home .top div:first-child {
  transform: translateX(0) !important;
  transition: transform 0.6s 0.6s;
}

/* enter */
.page-enter .home .top div:first-child {
  transform: translateX(200px)
}
.page-enter-active .home .top div:first-child {
  transform: translateX(0) !important;
  transition: transform 0.6s 0.6s;
}

/* exit */
.page-exit .home .top div:first-child {
  transform: translateX(0) !important;
}
.page-exit-active .home .top div:first-child {
  transform: translateX(300px) !important;
  transition: transform 0.6s;
  transition-delay: 0;
} 

/* Middle Text (Name) & Selfie & bottom text*/
/* appear */
 .page-appear .home .top div:nth-child(2), 
 .page-appear .selfie, 
 .page-appear .bottom a {
  transform: scale(0);
}
.page-appear-active .home .top div:nth-child(2), 
.page-appear-active .selfie, 
.page-appear-active .bottom a {
  transform: scale(1) !important;
  transition: transform 0.6s 0.6s;
}

/* enter */
 .page-enter .home .top div:nth-child(2), 
 .page-enter .selfie, 
 .page-enter .bottom a {
  transform: scale(0);
}
.page-enter-active .home .top div:nth-child(2), 
.page-enter-active .selfie, 
.page-enter-active .bottom a {
  transform: scale(1) !important;
  transition: transform 0.6s 0.6s;
}

/* exit */
.page-exit .home .top div:nth-child(2), 
.page-exit .selfie, 
.page-exit .bottom a {
  transform: scale(1) !important;
}
.page-exit-active .home .top div:nth-child(2), 
.page-exit-active .selfie, 
.page-exit-active .bottom a {
  transform: scale(0) !important;
  transition: transform 0.6s 0s;
}

/* Lower Text (Developer) */
/* appear */
.page-appear .home .top div:last-child {
  transform: translateX(-200px);
}
.page-appear-active .home .top div:last-child {
  transform: translateX(0) !important;
  transition: transform 0.6s 0.6s;
}

/* enter */
.page-enter .home .top div:last-child {
  transform: translateX(-200px);
}
.page-enter-active .home .top div:last-child {
  transform: translateX(0) !important;
  transition: transform 0.6s 0.6s;
}

/* exit */
.page-exit .home .top div:last-child {
  transform: translateX(0) !important;
}
.page-exit-active .home .top div:last-child {
  transform: translateX(-300px) !important;
  transition: transform 0.6s 0s;
}


/* About page exit and enter css transitions */
/* top text & middle text*/
/* appear */
.page-appear .about .top .text,
.page-appear .about .middle p {
  transform: scale(0);
}
.page-appear-active .about .top .text,
.page-appear-active .about .middle p {
  transform: scale(1);
  transition: transform 0.6s 0.6s;
}
/* enter */
.page-enter .about .top .text,
.page-enter .about .middle p {
  transform: scale(0);
}
.page-enter-active .about .top .text,
.page-enter-active .about .middle p {
  transform: scale(1);
  transition: transform 0.6s 0.6s;
}

/* exit */
.page-exit .about .top .text, 
.page-exit .about .middle p {
  transform: scale(1);
}
.page-exit-active .about .top .text,
.page-exit-active .about .middle p {
  transform: scale(0);
  transition: transform 0.6s;
}

/* logos */
/* appear */
.page-appear .about .logos img {
  transform: translateY(100px);
}
.page-appear-active .about .logos img {
  transform: translateY(0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(.21,.69,.57,1.61);
}

/* enter */
.page-enter .about .logos img {
  transform: translateY(100px);
}
.page-enter-active .about .logos img {
  transform: translateY(0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(.21,.69,.57,1.61);
}

/* exit */
.page-exit .about .logos img {
  transform: translateY(0);
}
.page-exit-active .about .logos img {
  transform: translateY(100px);
  transition: transform 0.6s;
  transition-delay: 0s !important;
}

/* Projects transitions */
/* h1 */
/* appear */
.page-appear .projects .top h1 {
  transform: scale(0);
}

.page-appear-active .projects .top h1 {
  transform: scale(1);
  transition: transform 0.6s 0.6s;
}

/* enter */
.page-enter .projects .top h1 {
  transform: scale(0);
}

.page-enter-active .projects .top h1 {
  transform: scale(1);
  transition: transform 0.6s 0.6s;
}

/* exit */
.page-exit .projects .top h1 {
  transform: scale(1);
}
.page-exit-active .projects .top h1 {
  transform: scale(0);
  transition: transform 0.6s;
}

/* figures */
/* appear */
.page-appear .projects .top figure {
  transform: translateY(100%);
}

.page-appear-active .projects .top figure {
  transform: translateY(0);
  transition: transform 0.6s 0.6s;
  transition-timing-function: cubic-bezier(.21,.69,.24,1.28);
}

/* enter */
.page-enter .projects .top figure {
  transform: translateY(100%);
}

.page-enter-active .projects .top figure {
  transform: translateY(0);
  transition: transform 0.6s 0.6s;
  transition-timing-function: cubic-bezier(.21,.69,.24,1.28);
}

/* exit */
.page-exit .projects .top figure {
  transform: translateY(0);
}
.page-exit-active .projects .top figure {
  transform: translateY(100%);
  transition: transform 0.6s;
}

/* Contact transitions */
/* h1, p, and form*/
/* appear */
.page-appear .contact .top h1,
.page-appear .contact .middle p,
.page-appear .contact .bottom form {
  transform: scale(0);
}

.page-appear-active .contact .top h1,
.page-appear-active .contact .middle p,
.page-appear-active .contact .bottom form {
  transform: scale(1);
  transition: transform 0.6s 0.6s;
}

/* enter */
.page-enter .contact .top h1,
.page-enter .contact .middle p,
.page-enter .contact .bottom form {
  transform: scale(0);
}

.page-enter-active .contact .top h1,
.page-enter-active .contact .middle p,
.page-enter-active .contact .bottom form {
  transform: scale(1);
  transition: transform 0.6s 0.6s;
}

/* exit */
.page-exit .contact .top h1,
.page-exit .contact .middle p,
.page-exit .contact .bottom form {
  transform: scale(1);
}
.page-exit-active .contact .top h1,
.page-exit-active .contact .middle p,
.page-exit-active .contact .bottom form {
  transform: scale(0);
  transition: transform 0.6s;
}


/* logos */
/* appear */
.page-appear .contact .middle img {
  transform: translateY(100px);
}
.page-appear-active .contact .middle img {
  transform: translateY(0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(.21,.69,.57,1.61);
}

/* enter */
.page-enter .contact .middle img {
  transform: translateY(100px);
}
.page-enter-active .contact .middle img {
  transform: translateY(0);
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(.21,.69,.57,1.61);
}

/* exit */
.page-exit .contact .middle img {
  transform: translateY(0);
}
.page-exit-active .contact .middle img {
  transform: translateY(100px);
  transition: transform 0.6s;
  transition-delay: 0s !important;
}

/* Media querries */
@media (min-height: 900px) {
  .app {
    height: 100%;
    min-height: 100vh;
  }
  
  .projects .top {
    display: flex;
    flex-direction: column;
  }

  .projects .top figure {
    height: 290px;
    width: 450px;
    margin: 1rem 5vw;
  }

  .projects .bottom a {
    margin-top: -2rem;
  }
}

@media (max-width: 960px) {
  .app {
    height: 100%;
    min-height: 100vh;
  }

  .home .top h1 div:first-child {
    bottom: 100px;
  }
  .home .top h1 div:nth-child(3) {
    top: 100px;
  }

  .about, .contact, .home, .projects {
    height: 100%;
    margin-bottom: 40px;
  }

  .projects .top figure {
    height: 350px;
    width: 400px;
    max-width: 92vw;
  }

   .about .bottom a  {
    margin-top: 40px;
  }

  .projects .bottom a {
    margin-top: 20px;
  }

  .about .middle .logos {
    max-width: 90vw;
  }

  .about .middle .logos img {
    margin: 0 1rem;
  }
}

@media (max-width: 880px) {
  .nav .hamburger {
    display: flex;
    width: 32px;
    height: 32px;
    margin-left: auto;
    transition: all 0.5s;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 100;
  }

  .nav .hamburger * {
    transition: all 0.5s;
  }

  .nav .hamburger:hover {
    cursor: pointer;
  }

  .animate {
    transform: rotate(180deg);
  }

  .animate .middle1 {
    transform: rotate(45deg);
    transform-origin: 50%;
  }

  .animate .middle2 {
    transform: rotate(-45deg);
    transform-origin: 50%;
  }

  .animate .bottom, 
  .animate .top {
    opacity: 0;
  }

  .nav {
    height: 70px;
    transition: height 0.75s;
  }

  .nav-list {
    flex-direction: column;
    height: 380px;
    transition-delay: 0.1s;
  }

  .nav-links {
    transform: translateY(-300px);
    opacity: 0;
    transition: transform 0.75s, opacity 0.45s;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .app .nav .nav-links a {
    margin: 20px;
  }

  .nav-list .nav-links {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transform: translateY(30px);
    opacity: 1;
    transition: transform 0.75s, opacity 0.15s;
  }
}



@media (max-width: 775px) {
  .home .top h1 div:first-child {
    font-size: 1.8rem;
    bottom: 135px;
  }
  .home .top h1 div:nth-child(2) {
    font-size: 60px;
  }
  .home .top h1 div:nth-child(3) {
    font-size: 1.8rem;
    top: 135px;
  }
}

@media (max-width: 670px) {
  .home .top h1 div:first-child {
    font-size: 2rem;
    bottom: 130px;
  }
  .home .top h1 div:nth-child(3) {
    top: 130px;
    font-size: 2rem;
  }
}

@media (max-width: 550px) {
  .svg {
    height: 60px;
    width: 60px;
    left: 0px;
  }
}

@media (max-width: 525px) {
  .home .top .selfie {
    width: 90vw;
    height: 90vw;
  }
}

@media (max-width: 495px) {
  .home .top h1 div:first-child {
    right: 0vw;
    bottom: 30vw;
  }
  .home .top h1 div:nth-child(2) {
    text-shadow: 3px 3px 3px black;
  }
  .home .top h1 div:nth-child(3) {
    left: 0vw;
    top: 30vw;
  }

  .app .bottom a {
    font-size: 6vw;
  }
}

@media (max-width: 400px) {
  .svg {
    height: 40px;
    width: 40px;
    left: -5px;
  }

  .app .bottom a {
    font-size: 1rem;
  }
}

@media (max-height: 550px) {
  .home .top h1 div:first-child {
    font-size: 7vh;
    bottom: 10vh;
  }
  .home .top h1 div:nth-child(2) {
    font-size: 12vh;
  }
  .home .top h1 div:nth-child(3) {
    font-size: 7vh;
    top: 10vh;
  }
}

@media (max-width: 320px) {
  .home .top h1 div:first-child {
    font-size: 5vh;
    bottom: 7vh;
  }
  .home .top h1 div:nth-child(2) {
    font-size: 10vh;
  }
  .home .top h1 div:nth-child(3) {
    font-size: 5vh;
    top: 7vh;
  }
}

